<template>
    <cis-head
        :title="$page.props.title"
    />
    <v-app
        id="root"
    >
        <notifications position="top right"/>
        <v-navigation-drawer
            v-if="$vuetify.display.mdAndDown"
            v-model="drawer"
            :disable-resize-watcher="true"
            width="300"
            app
            theme="light"
        >
            <div class="d-flex justify-end px-2 py-2">
                <v-btn variant="text" size="small" :icon="mdiWindowClose" @click="drawer = false"></v-btn>
            </div>
            <div>
                <v-list class="c-mobile-menu" v-if="menu.length" v-model:opened="menuModel">
                    <div v-for="item in menu"
                         :key="item.id"
                    >
                        <v-list-group
                            v-if="item.children.length"
                            color="black"
                            :value="item.id"
                        >
                            <template v-slot:activator="activator">
                                <v-list-item
                                    v-bind="activator?.props"
                                    color="cis-accent-color"
                                    class="border-b"
                                    v-bind:title.attr="item.name"
                                >
                                    <Link :href="item.url" :onFinish="() => drawer = false" @click.prevent.stop>
                                        <v-list-item-title class="text-subtitle-2">
                                            {{ item.name }}
                                        </v-list-item-title>
                                    </Link>
                                </v-list-item>
                            </template>
                            <v-list-item
                                v-for="child in item.children"
                                color="black"
                                :href="child.url"
                                :active="activeMenu[child.id]"
                                @click.prevent="router.visit(child.url, {onFinish(){
                                        drawer = false
                                    }})"
                                class="border-b"
                                :key="child.id"
                                v-bind:title.attr="child.name"
                            >
                                <v-list-item-title class="text-subtitle-2">
                                    {{ child.name }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list-group>
                        <v-list-item
                            v-else
                            color="black"
                            class="border-b"
                            :href="item.url"
                            @click.prevent="router.visit(item.url, {onFinish() {
                                    drawer = false
                                }})"
                            v-bind:title.attr="item.name"
                        >
                            <v-list-item-title class="text-subtitle-2">
                                {{ item.name }}
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </div>
        </v-navigation-drawer>
        <v-navigation-drawer
            v-if="!$page.props.isOrdersRoute"
            v-model="rightDrawer"
            :disable-resize-watcher="true"
            location="right"
            app
            temporary
            width="312"
        >
            <SideCart
                v-if="cartStore.cartItems.length"
                :open="rightDrawer"
                @cartClick="rightDrawer = false"
            ></SideCart>
            <v-banner v-else>
                <v-banner-text>
                    {{ $t('basic-layout.your_cart_is_empty')}}
                </v-banner-text>
            </v-banner>
        </v-navigation-drawer>
        <app-bar
            :menu="menu"
            :menu-model="menuModel"
            :active-menu="activeMenu"
            @close-left-drawer="drawer = !drawer"
            @close-right-drawer="rightDrawer = !rightDrawer"
        >
            <template #append="{shrink}" v-if="$vuetify.display.lgAndUp">
                <search
                    v-if="!shrink"
                    :show="$vuetify.display.lgAndUp"
                    :mobile="false"
                >
                </search>
            </template>
        </app-bar>
        <v-main id="main" name="main">
            <breadcrumb v-if="!group?.id && !$page.props.globalBreadcrumbOverride" :items="$page.props.breadcrumb"></breadcrumb>
            <slot></slot>
        </v-main>
        <lazy-hydration-wrapper when-visible>
            <v-footer class=" flex-grow-0 flex-wrap px-0 pt-0" name="app-footer">
                <newsletter-el></newsletter-el>
                <v-container class="flex-1-1-100">
                    <v-row class="pt-8">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <div class="text-subtitle-1 mb-2">{{ $t('basic-layout.links')}}</div>
                            <div>
                                <Link :href="route('aboutUs')" class="text-grey-darken-2 to-warning text-subtitle-2">
                                    {{ $t('basic-layout.about_us')}}
                                </Link>
                            </div>
                            <div>
                                <Link :href="route('contact')" class="text-grey-darken-2 to-warning text-subtitle-2">
                                    {{ $t('basic-layout.contact')}}
                                </Link>
                            </div>
                            <div>
                                <Link :href="route('manufacturersList')" class="text-grey-darken-2 to-warning text-subtitle-2">
                                    {{ $tChoice('eshop::group-page.manufacturers', 2) }}
                                </Link>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <div class="text-subtitle-1 mb-2">{{ $t('basic-layout.about_your_purchase')}}</div>
                            <div>
                                <Link :href="route('terms')" class="text-grey-darken-2 to-warning text-subtitle-2">
                                    {{ $t('basic-layout.terms_and_conditions')}}
                                </Link>
                            </div>
                            <div>
                                <Link :href="route('gdpr')" class="text-grey-darken-2 to-warning text-subtitle-2">
                                    {{ $t('basic-layout.gdpr')}}
                                </Link>
                            </div>
                            <div>
                                <Link :href="route('cession')" class="text-grey-darken-2 to-warning text-subtitle-2">
                                    {{ $t('basic-layout.cession')}}
                                </Link>
                            </div>
                            <div>
                                <Link :href="route('cession_lesson')" class="text-grey-darken-2 to-warning text-subtitle-2">
                                    {{ $t('basic-layout.cession_lesson')}}
                                </Link>
                            </div>
                            <div>
                                <Link :href="route('faq')" class="text-grey-darken-2 to-warning text-subtitle-2">
                                    {{ $t('basic-layout.faq')}}
                                </Link>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <div class="text-subtitle-1 mb-2">{{ $t('basic-layout.social_networks')}}</div>
                            <div>
                                <a
                                    class="text-grey-darken-2 to-warning text-subtitle-2"
                                    href="https://www.instagram.com/durance.cz/profilecard/?igsh=ZHl5OHJ0eG1zdTI="
                                >
                                    Instagram
                                </a>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-divider/>
                        </v-col>
                        <v-col cols="12" class="text-grey-darken-2 pt-0 text-subtitle-2 d-flex align-center justify-space-between ga-3">
                            <span>Copyright © {{ year }} CISApp {{ $t('basic-layout.all_rights_reserved')}}</span>
                            <a href="https://www.comgate.cz/platebni-brana" rel="nofollow noopener"><v-img v-if="$vuetify.display.mdAndUp" :src="comgateLogo.src" width="500" :alt="comgateLogo.alt"/></a>
                            <v-btn
                                class="text-none px-0 text-subtitle-2"
                                variant="plain"
                                color="grey"
                                @click="showModalCookie"
                            >
                                {{ $t('basic-layout.cookies_settings')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="$vuetify.display.smAndDown" justify="center">
                        <v-col cols="auto">
                            <a href="https://www.comgate.cz/platebni-brana"><v-img :src="comgateLogo.src" width="500" :alt="comgateLogo.alt"/></a>
                        </v-col>
                    </v-row>
                </v-container>
                <div id="footer-teleport" class="flex-1-1-100"></div>
            </v-footer>
        </lazy-hydration-wrapper>
        <component v-if="$page.props.user.token" :is="liveEdit"></component>
    </v-app>
</template>

<script setup>
import {computed, defineAsyncComponent, inject, nextTick, ref, watch} from "vue";
import {usePage, Link, router} from "@inertiajs/vue3";
import CisHead from "../components/CisHead.vue";
import axios from "axios";
//import {listen} from "../../utils/echo";
import {mdiWindowClose} from "@mdi/js";
import AppBar from "../components/AppBar.vue";
import Search from "../components/Search.vue"
import {LazyHydrationWrapper} from "vue3-lazy-hydration";
import {useNotify} from "../../../../Modules/Admin/Resources/assets/js/composables/notify";
import Breadcrumb from "../components/Breadcrumb.vue";
import {useCartStore} from "../../modules/eshop/store/cart";
import {useComparatorStore} from "../../modules/eshop/store/comparator.js";
import pinia from "../../plugins/pinia";

useNotify();
const route = inject('route');

const liveEdit = defineAsyncComponent(() => import('./../components/LiveEdit.vue'));
const newsletterEl = defineAsyncComponent(() => import('./../components/Newsletter.vue'));
const comgateLogo = computed(() => {
    return {
        src: '/comgate_footer.png?max-width=800',
        alt: 'Comgate'
    }
})
/*listen((data) => {
    console.log(data);
}, '.Pokus')*/
const showModalCookie = async () => {
    const script = document.getElementById('cookieScript');
    const modal = document.getElementById('modal-cookie')
    if (!script) {
        const {data} = await axios.get('/get-cookie-settings');
        document.body.insertAdjacentHTML('beforeend', data.html);
        document.head.append(document.createRange().createContextualFragment(data.script));
    }
    if (!modal) {
        const response = await fetch('/get-cookie-modal');
        const data = await response.json();
        document.body.insertAdjacentHTML('beforeend', data.html);
        window?.laravelCookieConsent.initButtons();
    }
    window?.laravelCookieConsent.modalShow();
}

const SideCart = defineAsyncComponent({
    loader: () => import("../../modules/eshop/frontend/SideCart.vue")
})

const user = computed(() => usePage().props.customer);
const menu = computed(() => usePage().props.menuGroups);

const group = computed(() => usePage().props.group);
const menuModel = ref(group.value?.path_ids || []);

watch(() => group.value?.path_ids, (val) => {
    menuModel.value = val;
})

const activeMenu = computed(() => {
    const active = {};
    if (group.value) {
        for (let i = 0; i < menu.value.length; i++) {
            if (group.value.path_ids.includes(menu.value[i].id)) {
                active[menu.value[i].id] = true;
            }
            if (menu.value[i].children) {
                for (let j = 0; j < menu.value[i].children.length; j++) {
                    if (group.value.path_ids.includes(menu.value[i].children[j].id)) {
                        active[menu.value[i].children[j].id] = true;
                        return active;
                    }
                }
            }
        }
    }
    return active;
})

const cartStore = useCartStore(pinia);
const comparatorStore = useComparatorStore(pinia);
comparatorStore.data = usePage().props.comparator
const drawer = ref(false);
const rightDrawer = ref(false);

nextTick(() => {
    if (!import.meta.env.SSR) {
        document.body.classList.remove('d-none')
    }
})
axios.interceptors.request.use(
    config => {
        const token = user.value?.token;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error)
    });
let first = true;
if (!import.meta.env.SSR) {
    router.on('navigate', () => {
        if (!first) {
            window.resetDL();
        }
        first = false;
    })
}

const year = ref((new Date()).getFullYear());

</script>

<style lang="scss">

</style>

<style scoped lang="scss">
:deep(.c-mobile-menu) {
    a {
        color: currentColor;
    }

    .v-list-item-title {
        &:first-child {
            display: none;
        }

        &:last-child {
            display: block;
        }
    }
}

#main {
    padding-top: calc(var(--v-layout-top) + 12px);
}
</style>
